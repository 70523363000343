import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import './ContactModal.css';
import { FaPhone, FaWhatsapp, FaStar, FaRegStar, FaTrash, FaEdit } from 'react-icons/fa';
import api from '../../api/axiosConfig';
import { toast } from 'react-toastify';
import noContactsImage from '../../assets/no-contacts.jpeg';
import { Tooltip } from '@mui/material';

const ContactModal = ({ onClose, open, setPrimaryContact, establishmentId }) => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAdding, setIsAdding] = useState(false);
  const [formData, setFormData] = useState({ name: '', role: '', phone: '' });
  const [editingContactId, setEditingContactId] = useState(null);
  const modalOpenRef = useRef(false);

  const fetchContacts = async () => {
    if (modalOpenRef.current) return; // Evita llamadas duplicadas
    modalOpenRef.current = true;

    try {
      const response = await api.get(`/api/establishments/${establishmentId}/contacts`);
      setContacts(response.data);
      setPrimaryContact(response.data.find((c) => c.isPrimary));
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setContacts([]); // No hay contactos
      } else {
        console.error('Error al obtener contactos:', error);
        toast.error('Hubo un problema al cargar los contactos.');
      }
    } finally {
      setLoading(false);
      modalOpenRef.current = false; // Resetea el estado
    }
  };

  const handleClose = () => {
    modalOpenRef.current = false;
    setContacts([]);
    setIsAdding(false);
    setLoading(true);
    onClose();
  };

  useEffect(() => {
    if (open) fetchContacts();
  }, [open]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSaveContact = async () => {
    try {
      if (editingContactId) {
        await api.put(`/api/establishments/${establishmentId}/contacts/${editingContactId}`, formData);
        toast.success('Contacto actualizado.');
      } else {
        await api.post(`/api/establishments/${establishmentId}/contacts`, formData);
        toast.success('Contacto agregado.');
      }
      fetchContacts();
      resetForm();
    } catch {
      toast.error('No se pudo guardar el contacto.');
    }
  };

  const handleDeleteContact = async (contactId, contactName) => {
    const confirm = window.confirm(`¿Deseas eliminar los datos de contacto: ${contactName}?`);
    if (!confirm) return;

    try {
      await api.delete(`/api/establishments/${establishmentId}/contacts/${contactId}`);
      toast.success('Contacto eliminado.');
      fetchContacts();
    } catch {
      toast.error('No se pudo eliminar el contacto.');
    }
  };

  const handleSetPrimaryContact = async (contactId) => {
    try {
      await api.put(`/api/establishments/${establishmentId}/contacts/${contactId}/set-primary`);
      toast.success('Contacto principal actualizado.');
      fetchContacts();
    } catch {
      toast.error('No se pudo actualizar el contacto principal.');
    }
  };

  const handleEditContact = (contact) => {
    setIsAdding(true);
    setFormData({ name: contact.name, role: contact.role, phone: contact.phone });
    setEditingContactId(contact._id);
  };

  const resetForm = () => {
    setIsAdding(false);
    setFormData({ name: '', role: '', phone: '' });
    setEditingContactId(null);
  };

  return (
    <Modal open={open} onClose={handleClose} className="contact-modal">
      <div className="contact-modal-content">
        <h2>Contactos del Establecimiento</h2>
        {loading ? (
          <p>Cargando contactos...</p>
        ) : contacts.length === 0 && !isAdding ? (
          <div className="no-contacts">
            <img src={noContactsImage} alt="No hay contactos" />
            <p>No hay contactos guardados, agrégalos para verlos aquí.</p>
            <button onClick={() => setIsAdding(true)} className="add-contact-button">
              Agregar Contacto
            </button>
          </div>
        ) : (
          <>
            {isAdding && (
              <div className="contact-form">
                <input type="text" name="name" placeholder="Nombre" value={formData.name} onChange={handleInputChange} />
                <input type="text" name="role" placeholder="Rol" value={formData.role} onChange={handleInputChange} />
                <input type="text" name="phone" placeholder="Teléfono" value={formData.phone} onChange={handleInputChange} />
                <div className="form-actions">
                  <button onClick={handleSaveContact} className="save-button">Guardar</button>
                  <button onClick={resetForm} className="cancel-button">Cancelar</button>
                </div>
              </div>
            )}
            {!isAdding &&
              contacts.map((contact) => (
                <div key={contact._id} className="contact-row">
                  <div className="contact-info">
                    <span><strong>{contact.name}</strong> - {contact.role}</span>
                    <div>
                      <Tooltip title="Llamar al contacto">
                        <a href={`tel:${contact.phone}`}><FaPhone className="action-icon" /></a>
                      </Tooltip>
                      <Tooltip title="Enviar mensaje por WhatsApp">
                        <a href={`https://wa.me/${contact.phone}`} target="_blank" rel="noreferrer">
                          <FaWhatsapp className="action-icon" />
                        </a>
                      </Tooltip>
                      <Tooltip title="Editar contacto">
                        <button onClick={() => handleEditContact(contact)} className="icon-button">
                          <FaEdit className="edit-icon" />
                        </button>
                      </Tooltip>
                      <Tooltip title="Marcar como Contacto Principal">
                        <button onClick={() => handleSetPrimaryContact(contact._id)} className="icon-button">
                          {contact.isPrimary ? (
                            <FaStar className="primary-icon filled" />
                          ) : (
                            <FaRegStar className="primary-icon" />
                          )}
                        </button>
                      </Tooltip>
                      <Tooltip title="Eliminar contacto">
                        <button onClick={() => handleDeleteContact(contact._id, contact.name)} className="icon-button">
                          <FaTrash className="delete-icon" />
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ))}
            <button onClick={() => setIsAdding(true)} className="add-contact-button">Agregar Contacto</button>
          </>
        )}
        <div className="modal-actions">
          <button onClick={onClose} className="cancel-button">Cerrar</button>
        </div>
      </div>
    </Modal>
  );
};

ContactModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setPrimaryContact: PropTypes.func.isRequired,
  establishmentId: PropTypes.string.isRequired,
};

export default ContactModal;