import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import "./LandingHeader.css";
import BioSafeAppIcon from '../../assets/landingPage/icon512.png';

const LandingHeader = ({ setScrollToSection }) => {
    const [menuOpen, setMenuOpen] = useState(false); // Estado para controlar el menú
    const navigate = useNavigate();
    const location = useLocation();

    const handlePricingClick = (event) => {
        event.preventDefault();
        if (location.pathname !== "/") {
            navigate("/", { state: { scrollToSection: "pricing" } });
        } else if (setScrollToSection) {
            setScrollToSection("pricing");
        }
    };

    return (
        <header className="header">
            <div className="container">
                <a href="/">
                    <div className="logo">
                        <img src={BioSafeAppIcon} alt="BioSafeApp" />
                        <h1>BioSafeApp</h1>
                    </div>
                </a>
                <div className="menu-toggle" onClick={() => setMenuOpen(!menuOpen)}>
                    &#9776; {/* Ícono de hamburguesa */}
                </div>
                <nav className={`nav ${menuOpen ? "open" : ""}`}>
                    <ul>
                        <li><a href="/about-us">¿Quiénes somos?</a></li>
                        <li><a href="#pricing" onClick={handlePricingClick}>Precio</a></li>
                        <li><a href="/contact">¡Contáctanos!</a></li>
                        <li><a href="/login">Login</a></li>
                        <li><a href="/signup" className="register-button">Registrarse</a></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

LandingHeader.propTypes = {
    setScrollToSection: PropTypes.func.isRequired,
};

export default LandingHeader;
